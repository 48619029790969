<template>
  <b-modal v-model="conditionPay" @hide="onHide" hide-footer hide-header centered size="md">
    <validation-observer ref="payRef">
      <b-form class="auth-login-form mt-2" @submit.prevent="onSubmitPay">
        <b-form-group label="Total" label-for="total">
          <b-form-input id="total" name="total" disabled :value="itemAppointment.total" />
        </b-form-group>

        <!-- payment day -->
        <b-row>
          <b-col cols="auto">
            <validation-provider #default="{ errors }" name="Payment Date" rules="required" ref="dateRules">
              <b-form-group label="Payment Date" label-for="payment-date">
                <flat-pickr v-model="paymentInfo.paymentDate" :config="config" class="form-control"
                  placeholder="YYYY-MM-DD" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="auto" align-self="center">
            <b-button class="ml-3" variant="success" @click="changeMethod"> Split Pay </b-button>
          </b-col>
        </b-row>

        <!-- <validation-provider #default="{ errors }" name="Payment Date" rules="required" ref="dateRules"> -->
        <!-- <checkCupon :cupon.sync="cupon" @cancelCupon="cancelCupon" /> -->

        <!-- payment method -->
        <div v-if="conditionSplit">
          <validation-provider #default="{ errors }" name="Payment Method" rules="required">
            <b-form-group label="Payment Method" label-for="payment-method" :state="errors.length > 0 ? false : null"
              class="my-1">
              <treeselect v-model="paymentInfo.paymentMethod" :options="paymentMethodOptions" :clearable="true"
                :normalizer="normalizer" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="Payment Amount" :rules="this.isPartialPay
            ? `required|min_value:0|max_value:${itemAppointment.total}`
            : `required|min_value:${itemAppointment.total}|max_value:${itemAppointment.total}`
            ">
            <b-form-group label="Payment Amount" label-for="payment-amount">
              <b-form-input id="payment-amount" name="payment-amount" v-model="amountCheck"
                :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>
        <div class="my-1" v-else>
          <b-row>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Credit Card" :rules="this.isPartialPay
                ? {
                  required: true,
                  totalRulesPartial: {
                    total: itemAppointment.total,
                    totalPay: splitPay,
                  },
                }
                : {
                  required: true,
                  totalRules: {
                    total: itemAppointment.total,
                    totalPay: splitPay,
                  },
                }
                " ref="rulesCredit">
                <b-form-group label="Credit Card" label-for="credit-card" :invalid-feedback="validateTotal">
                  <b-form-input id="credit-card" name="credit-card" v-model="itemAppointment.cc"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider></b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Cash" :rules="this.isPartialPay
                ? {
                  required: true,
                  totalRulesPartial: {
                    total: itemAppointment.total,
                    totalPay: splitPay,
                  },
                }
                : {
                  required: true,
                  totalRules: {
                    total: itemAppointment.total,
                    totalPay: splitPay,
                  },
                }
                " ref="rulesCredit">
                <b-form-group label="Cash" label-for="cash" :invalid-feedback="validateTotal">
                  <b-form-input id="cash" name="cash" v-model="itemAppointment.cash"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider></b-col>
          </b-row>
        </div>

        <!-- internal payment note-->
        <validation-provider #default="{ errors }" name="Internal Payment Note">
          <b-form-group label="Internal Payment Note" label-for="internal-payment-note"
            :state="errors.length > 0 ? false : null" class="my-1">
            <b-form-textarea id="internal-payment-note" v-model="itemAppointment.note" placeholder="Internal payment note"
              no-resize rows="3" />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- <b-form-group label="Partial Payment" label-for="Partial Payment">
          <b-form-checkbox v-model="isPartialPay"> </b-form-checkbox>
        </b-form-group> -->

        <div class="d-flex justify-content-end mt-2">
          <b-button type="button" class="mr-2" variant="outline-danger" @click="onHide">
            {{ $t("Cancel") }}
          </b-button>
          <b-button variant="primary" type="submit" :disabled="loading || itemAppointment.pay_status_id === 1">
            <span v-if="!loading">Pay</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
    <ToastNotification ref="toast" />
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BCol,
  BRow,
  BButton,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

import flatPickr from "vue-flatpickr-component";
import checkCupon from "@/components/checkCupon";

import SpinnerLoading from "@/components/SpinnerLoading";
import ToastNotification from "@/components/ToastNotification";
import { getTotal } from "@/mixins/cupon";

import axiosOV from "@/core/services/api/office-visit/index";
import Swal from "sweetalert2";

import NotificationMixin from "@/mixins/NotificationMixin";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BCol,
    BRow,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormCheckbox,

    flatPickr,

    ValidationProvider,
    ToastNotification,
    ValidationObserver,
    checkCupon,
    SpinnerLoading,
  },
  props: {
    itemAppointment: {
      type: Object,
      required: true,
    },
  },
  mixins: [NotificationMixin],
  watch: {
    isPartialPay(newValue) {
      if (newValue === true) {
        Swal.fire({
          title: '<h3 class="font-weight-bolder">Selected Partial Payment</h3>',
          showCancelButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-outline-primary",
          },
          buttonsStyling: false,
        });
        this.methodAxios = "procedurePayPartial";
      } else {
        this.methodAxios = "procedurePay";
      }
    },
  },
  computed: {
    splitPay() {
      return (
        parseInt(this.itemAppointment.cc) + parseInt(this.itemAppointment.cash)
      );
    },
    validateTotal() {
      extend("totalRules", {
        params: ["total", "totalPay"],
        validate: (value, { total, totalPay }) => {
          if (totalPay >= total) {
            return true;
          } else {
            return false;
          }
        },
        message: (field, { total }) =>
          `El ${field} must be higher or greater to ${total}`,
      });
      extend("totalRulesPartial", {
        params: ["total", "totalPay"],
        validate: (value, { total, totalPay }) => {
          if (totalPay <= total) {
            return true;
          } else {
            return false;
          }
        },
        message: (field, { total }) =>
          `El ${field} must be under or greater to ${total}`,
      });
    },
    amountCheck: {
      get() {
        if (this.paymentInfo.paymentMethod == 1) {
          return this.itemAppointment.cash;
        } else {
          return this.itemAppointment.cc;
        }
      },
      set(val) {
        if (this.paymentInfo.paymentMethod == 1) {
          this.itemAppointment.cash = val;
          this.itemAppointment.cc = null;
        } else {
          this.itemAppointment.cc = val;
          this.itemAppointment.cash = null;
        }
      },
    },
  },
  data() {
    return {
      conditionPay: true,
      cupon: '',
      conditionSplit: true,
      isPartialPay: false,
      methodAxios: "procedurePay",
      paymentInfo: {
        paymentDate: new Date(),
        paymentMethod: null,
      },
      paymentMethodOptions: [
        {
          name: "Cash",
          id: 1,
        },
        {
          name: "Credit Card",
          id: 2,
        },
      ],
      config: {
        static: true,
      },
      loading: false,
      validCupon: false,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  mounted() {
    if (this.itemAppointment.cc != null && this.itemAppointment.cash != null) {
      this.conditionSplit = false;
    } else if (
      this.itemAppointment.cc != null &&
      this.itemAppointment.cash == null
    ) {
      this.paymentInfo.paymentMethod = 2;
    } else {
      this.paymentInfo.paymentMethod = 1;
    }
  },
  methods: {
    verifyCupon() {
      axiosOV.verifyCupon({ cupon: this.cupon }).then(({ status, data }) => {
        this.validCupon = false
        if (status != "ok") return this.$refs.toast.danger("Error, cupon not found or expired!");
        this.validCupon = true
        this.itemAppointment.total = getTotal({ campaignType: data.campaign.type, total: this.itemAppointment.total, campaignValue: data.campaign.value })
        return this.$refs.toast.success("cupon found!");
      }).catch((e) => {
        this.$refs.toast.danger("Error, cupon not found or expired!");
      })
    },
    onHide() {
      this.$emit("closeModalPay", false);
    },
    changeMethod() {
      this.conditionSplit = !this.conditionSplit;
    },
    onSubmitPay() {
      this.$refs.payRef.validate().then(async (success) => {
        if (success) {
          this.loading = true;
          const data = {
            cash: this.itemAppointment.cash,
            cc: this.itemAppointment.cc,
            note: this.itemAppointment.note,
            cupon: this.validCupon ? this.cupon : null
          };
          await axiosOV[this.methodAxios](this.itemAppointment.id, data)
            .then((res) => {
              const {
                type,
                message,
              } = NotificationMixin.methods.notificationCheck(res);
              this.loading = false;
              this.$emit("NotificationCheck", { type, message });
              this.$emit("paymentChecked", true);
            })
            .catch((e) => {
              this.loading = false;
            });
          this.$emit("closeModalPay", false);
        } else {
          console.log("No");
        }
      });
    },
    validationMethod() {
      return new Promise((resolve, reject) => {
        this.$refs.dateRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>
